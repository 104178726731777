import React from "react"
import PropTypes from "prop-types"
import "./layout.css"

const Layout = ({ children, size, inv }) => {

  return (
    <main style={{width: size && size[0], marginLeft: size && size[1]}}>
      {children}
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout